import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ProfileTitle } from ".";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import ModalComponent from "../components/modal/Modal";
import Space from "../components/space/Space";
import Text from "../components/text/Text";
import { ERROR_MSG, PROFILE_UPDATE_SUCCESS_MSG } from "../constants/common";
import { SET_ERROR, UPDATE_USER } from "../constants/contexConstant";
import {
    LANDING,
    // LOGIN,
    UPDATE_EMAIL_ENDPOINT,
    UPDATE_PROFILE_ENDPOINT,
} from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import auth from "../services/auth";
import request from "../services/request";
import BottomLineEmailInput from "./BottomLineEmailInput";
import BottomLineInput from "./BottomLineInput";
import RefInput from "./RefInput";
const FlewWrap = styled(Flex.Container)`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin-top: 0px;
    }
`;

const PersonalDetails = ({ title }) => {
    const { state, dispatch } = useContextState();

    const initializeState = useCallback((user = {}) => {
        return {
            name: user?.name || "",
            phoneNumber: "+" + user?.phoneNumber || "",
            email: user?.email || "",
            joiningDate: user?.joiningDate || "",

            pan:
                user?.kycTableDisplay?.isPanUploaded === true
                    ? user?.kycTableDisplay?.panKYCStatus
                    : null,

            aadhar:
                user?.kycTableDisplay?.isAadharUploaded === true
                    ? user?.kycTableDisplay?.aadharKYCStatus
                    : null,

            address: user?.address || "",
            dateOfBirth: user?.dateOfBirth || "",
            nominee: user?.nominee || "",
        };
    }, []);

    const [details, setDetails] = useState(initializeState(state.user));
    const [loading, setLoading] = useState(false);
    const [loadingEmailUpdate, setLoadingEmailUpdate] = useState(false);
    const [newDetails, setNewDetails] = useState({
        nominee: details.nominee,
        address: details.address,
    });
    const [newDetailsErr, setNewDetailsErr] = useState({
        nominee: "",
        address: "",
    });
    const [openModal, setOpenModal] = useState(null);
    const [email, setEmail] = useState(details.email);

    useEffect(() => {
        const userDetails = initializeState(state.user);
        setEmail(state.user?.email);
        setNewDetails({
            nominee: state.user?.nominee,
            address: state.user?.address,
        });
        setDetails(userDetails);
    }, [state.user, initializeState]);

    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDetails({ ...details, [name]: value });
    };

    const history = useHistory;

    useEffect(() => {
        if (openModal) {
            document.body.style.overflow = "hidden";
        }
        return () => (document.body.style.overflow = "unset");
    }, [openModal]);

    const handleUpdateEmail = async () => {
        const body = {
            old_email: details.email,
            new_email: email,
        };
        try {
            setLoadingEmailUpdate(true);
            await request.authPut({ endpoint: UPDATE_EMAIL_ENDPOINT, body });
            toast.success(
                "Email has been updated, you will be logged out shortly!"
            );
            setLoadingEmailUpdate(false);
            setOpenModal(null);
            setTimeout(async () => {
                await auth.logout(dispatch);
                history.push(LANDING);
            }, 2000);
        } catch (error) {
            setLoadingEmailUpdate(false);
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            setOpenModal(null);
        }
    };

    const handleCancel = () => {
        if (openModal?.name === "email") {
            setEmail(details.email);
        } else if (openModal?.name === "nominee") {
            setNewDetails({ ...newDetails, nominee: details.nominee });
        } else if (openModal?.name === "address") {
            setNewDetails({ ...newDetails, address: details.address });
        }
        setOpenModal(null);
        setLoadingEmailUpdate(false);
        setLoading(false);
    };

    const emailRef = useRef();

    const nomineeRef = useRef();
    const addressRef = useRef();

    const updateNewDetails = (body) => {
        setOpenModal(null);
        setLoading(true);
        request
            .authPut({ endpoint: UPDATE_PROFILE_ENDPOINT, body })
            .then((data) => {
                dispatch({
                    type: UPDATE_USER,
                    payload: data.data.investor_data,
                });
                toast.success(PROFILE_UPDATE_SUCCESS_MSG);
                setLoading(false);
                setOpenModal(null);
            })
            .catch((err) => {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: err || ERROR_MSG,
                });
                setOpenModal(null);
            });
    };

    const handleUpdateField = () => {
        if (openModal?.name === "email") {
            handleUpdateEmail();
        } else if (openModal?.name === "nominee") {
            if (newDetails.nominee === "") {
                setNewDetailsErr({
                    ...newDetailsErr,
                    nominee: "Nominee name required!",
                });
                return;
            }
            let body = {
                nominee: newDetails.nominee,
            };
            updateNewDetails(body);
            setNewDetailsErr({ ...newDetailsErr, nominee: "" });
        } else if (openModal?.name === "address") {
            const slicedLineText = newDetails.address.replace(
                /(\r\n|\n|\r)/gm,
                ","
            );
            let addressArray = slicedLineText.split(",");
            if (newDetails.address === "") {
                setNewDetailsErr({
                    ...newDetailsErr,
                    address: "Address required!",
                });
                return;
            } else if (addressArray.length < 0 || addressArray === undefined) {
                setNewDetailsErr({
                    ...newDetailsErr,
                    address: "Invalid Adress!",
                });
                return;
            }
            let body = {
                addressObj: {
                    address: addressArray[0] || "",
                    city: addressArray[1] || "",
                    state: addressArray[2] || "",
                    pinCode: addressArray[3] || "",
                },
            };
            updateNewDetails(body);
            setNewDetailsErr({ ...newDetailsErr, address: "" });
        }
    };

    return (
        <div>
            <ProfileTitle title={title} />
            <FlewWrap>
                <Flex.Container justifyContent="space-between">
                    <Flex.Item
                        width="45%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <BottomLineInput
                            label="Full Name"
                            placeholder="Full Name"
                            type="text"
                            onChange={handleOnChange}
                            name="name"
                            value={details.name}
                            readOnly
                        />
                        <BottomLineInput
                            label="Phone Number"
                            placeholder="932XXXX289"
                            onChange={handleOnChange}
                            name="phoneNumber"
                            value={details.phoneNumber}
                            readOnly
                        />

                        <RefInput
                            name="Address"
                            placeholder="Enter Your Address"
                            label="Address"
                            icon={false}
                            inputRef={addressRef}
                            oldValue={details.address}
                            currentValue={newDetails.address}
                            onChange={(e) =>
                                setNewDetails({
                                    ...newDetails,
                                    address: e.target.value,
                                })
                            }
                            readOnly
                            onClick={() => setOpenModal({ name: "address" })}
                            error={newDetailsErr.address}
                            rows="2"
                        />

                        {details.pan !== null ? (
                            <BottomLineInput
                                label="PAN"
                                placeholder="XXXXXX283"
                                onChange={handleOnChange}
                                name="pan"
                                readOnly
                                value={
                                    details.pan === "PENDING"
                                        ? " KYC Under Review"
                                        : "VERIFIED"
                                }
                            />
                        ) : (
                            <>
                                <Text
                                    style={{ marginBottom: "7px" }}
                                    color="darkSecondary"
                                    size="m"
                                >
                                    PAN
                                </Text>
                                <Space
                                    margin={["0 0 30px 0"]}
                                    tabletmargin={[" 0 0 20px 0"]}
                                >
                                    <Text
                                        style={{ marginBottom: "30px" }}
                                        color="error"
                                        size="m"
                                    >
                                        KYC Not Started
                                    </Text>
                                </Space>
                            </>
                        )}
                    </Flex.Item>
                    <Flex.Item
                        width="45%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <BottomLineInput
                            label="Date of Birth"
                            placeholder="DD/MM/YYYY"
                            onChange={handleOnChange}
                            name="dateOfBirth"
                            readOnly
                            value={details.dateOfBirth}
                        />
                        <BottomLineEmailInput
                            oldEmail={details.email}
                            email={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onClick={() => setOpenModal({ name: "email" })}
                            ref={emailRef}
                            label="Email Id"
                        />

                        {details.aadhar !== null ? (
                            <BottomLineInput
                                label="Aadhar"
                                placeholder="XXXXXX289"
                                onChange={handleOnChange}
                                name="aadhar"
                                readOnly
                                value={
                                    details.aadhar === "PENDING"
                                        ? "KYC Under Review"
                                        : "VERIFIED"
                                }
                            />
                        ) : (
                            <>
                                <Text
                                    style={{ marginBottom: "7px" }}
                                    color="darkSecondary"
                                    size="m"
                                >
                                    Aadhar
                                </Text>
                                <Space
                                    margin={["0 0 30px 0"]}
                                    tabletmargin={[" 0 0 20px 0"]}
                                >
                                    <Text
                                        style={{ marginBottom: "30px" }}
                                        color="error"
                                        size="m"
                                    >
                                        KYC Not Started
                                    </Text>
                                </Space>
                            </>
                        )}
                    </Flex.Item>
                </Flex.Container>
                {/* <Flex.Container
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    <Flex.Item width="45%" mobileWidth="100%">
                        <Space mobilemargin={["20px 0 0 0 "]}>
                            <ButtonStyled
                                onClick={handleUpdate}
                                type="solid"
                                backgroundColor="primary"
                                disabled={loading}
                                isLoading={loading}
                            >
                                Update Details
                            </ButtonStyled>
                        </Space>
                    </Flex.Item>
                </Flex.Container> */}
            </FlewWrap>
            <ModalComponent isOpen={openModal}>
                <Flex.Container
                    style={{
                        width: "100%",
                        background: "white",
                        padding: "2rem",
                        borderRadius: "10px",
                    }}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Text size="l" color="">
                        Are you sure to change {openModal?.name}?
                    </Text>
                    <div style={{ marginTop: "1rem" }}>
                        <Button
                            onClick={handleCancel}
                            size="s"
                            style={{ marginRight: "20px" }}
                            color="primary"
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleUpdateField}
                            type="solid"
                            size="s"
                            disabled={loadingEmailUpdate || loading}
                            isLoading={loadingEmailUpdate || loading}
                        >
                            Yes
                        </Button>
                    </div>
                </Flex.Container>
            </ModalComponent>
        </div>
    );
};

export default PersonalDetails;
